import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import Form from "../components/Form";

const topics = [
  "Am I saving enough to eventually retire?",
  "Do I have the right employer sponsored retirement plan for my staff?",
  "What are some savings options?",
  "Should I offer disability or Long Term Care insurance?",
  "Should I be contributing to a health savings account?",
  "How can I best utilize a company’s stock incentive plan?",
  "Do I need more, less or difference life insurance?",
  "Are my investments properly diversified?",
  "How can I reduce my tax liability?",
  "Should I provide a Roth option for my employees?",
  "Help me best utilize all my employer benefits?",
  "How do I transition out of my business?",
  "Too many questions, not all are listed...",
  "All of the above...",
];

const grossMonthlyIncome = [
  "< $2,500",
  "$2,501 - $5,000",
  "$5,001 - $7,500",
  "$7,501 - $10,000",
  "> $10,000",
];

const knowledgeLevel = [
  "They do not know the difference between a stock and a bond",
  "They know the basics about stocks, bonds and mutual funds",
  "They are quite knowledgeable about various types of investments",
  "I am not certain",
];

const howSaving = [
  "I fund individual retirement accounts",
  "I fund an employer sponsored retirement plan (Simple, SEP, 401k, 403b, 457, etc)",
  "I fund a pension plan",
  "I fund an annuity",
  "I fund other taxable investment accounts",
  "Other:",
];

const retirementBenefits = [
  "none",
  "<250,000",
  "$250,001 - $500,000",
  "$500,001 - $1,000,000",
  "$1,000,001 - $2,000,000",
  ">$2,000,001",
];

const otherBenefits = [
  "Medical, dental, and vision coverage?",
  "Life insurance?",
  "Long term care costs?",
  "Short and long term disability?",
  "Health savings accounts?",
];

const SmallBusinessOwnersQuestionnaire = ({ data }) => {
  const siteTitle = "Small Business Owners - Getting Started";
  const siteDescription = data.site.siteMetadata.description;

  return (
    <Layout location={data.site.siteMetadata.location} title={siteTitle}>
      <SEO title={siteTitle} description={siteDescription} />

      <div className="questionnaire-section">
        <div className="container">
          <h1 className="section-h text-center text-white">
            <span
              className="d-block text-uppercase"
              style={{ fontSize: "24px" }}
            >
              Getting Started
            </span>
            <strong>Small Business Owners</strong>
          </h1>
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="panel p-4 p-md-5" style={{ zIndex: 1 }}>
                <p className="small text-muted font-italic">
                  <span className="text-danger">*</span> indicates a required
                  field.
                </p>
                <Form
                  form_name="Small Business Owners Getting Started Questionnaire"
                  form_id="e9ec470f-4e57-4db5-8efe-8dc014acd0fe"
                  form_classname="regular-form"
                  thankyou_url="/form-submitted"
                  method="post"
                >
                  <div className="form-section">
                    <h2 className="h4 mb-4">Who are you?</h2>
                    <div className="row justify-content-start">
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label htmlFor="firstName">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="First Name"
                            id="firstName"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label htmlFor="lastName">
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="Last Name"
                            id="lastName"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label htmlFor="age">
                            Age <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="Age"
                            id="age"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="phone">
                            Phone <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="tel"
                            name="Phone"
                            id="phone"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="email">
                            Email Address <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="email"
                            name="Email"
                            id="email"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="company">Company</label>
                          <input
                            className="form-control"
                            type="text"
                            name="Company"
                            id="company"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="emp_company">
                            How many employees in your company?
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="How many employees in your company?"
                            id="emp_company"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="retirementPlan" className="w-auto">
                            Do you currently have an employer sponsored
                            retirement plan?
                          </label>
                          <div className="d-flex">
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="Do you currently have an employer sponsored retirement plan?"
                                id="yes_retirementPlan"
                                value="Yes"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="yes_retirementPlan"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="Do you currently have an employer sponsored retirement plan?"
                                id="no_retirementPlan"
                                value="No"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="no_retirementPlan"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="form-group">
                      <h2 className="h4 mb-4">
                        I don’t have the time to do/figure out the following,
                        but I know I should.
                      </h2>
                      <span className="d-block mb-2">
                        <label htmlFor="topics" className="w-auto">
                          Select all that you have questions on:
                        </label>
                      </span>
                      {topics.map((topic, i) => (
                        <div className="form-check ml-2" key={i}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="I don’t have the time to do/figure out the following, but I know I should."
                            id={`topics` + i}
                            value={topic}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`topics` + i}
                          >
                            {topic}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="form-section">
                    <h2 className="h4 mb-4">Where are you today?</h2>
                    <h3 className="h6 font-weight-bold my-3">Payroll</h3>
                    <div className="row justify-content-start pl-3">
                      <div className="col-12 mb-3">
                        <div className="form-group">
                          <label htmlFor="grossMonthlyIncome">
                            Employees average gross monthly income (
                            <strong>
                              <u>before</u>
                            </strong>{" "}
                            anything is withheld)
                          </label>
                          {grossMonthlyIncome.map((choice, i) => (
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="Employees average gross monthly income"
                                id={`grossMonthlyIncome` + i}
                                value={choice}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`grossMonthlyIncome` + i}
                              >
                                {choice}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <h3 className="h6 font-weight-bold my-3">
                      Budgeting Tools
                    </h3>
                    <div className="row justify-content-start pl-3">
                      <div className="col-12 mb-3">
                        <div className="form-group">
                          <label htmlFor="budgetingTools">
                            Do you provide access to any budget/spending tracker
                            linked to bank accounts and credit cards?
                          </label>
                          <div className="d-flex">
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="Do you provide access to any budget/spending tracker
                              linked to bank accounts and credit cards?"
                                id="yes_budgetingTools"
                                value="Yes"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="yes_budgetingTools"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="Do you provide access to any budget/spending tracker
                              linked to bank accounts and credit cards?"
                                id="no_budgetingTools"
                                value="No"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="no_budgetingTools"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-start mt-3">
                      <div className="col-12 mb-3">
                        <div className="form-group">
                          <label htmlFor="knowledgeLevel">
                            Your employees' level of investment knowledge
                          </label>
                          {knowledgeLevel.map((choice, i) => (
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="Your employees' level of investment knowledge"
                                id={`knowledgeLevel` + i}
                                value={choice}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`knowledgeLevel` + i}
                              >
                                {choice}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <div className="form-group">
                          <label htmlFor="howSaving">
                            How have your employees been saving for retirement
                            and/or other goals (check all that apply).
                          </label>
                          {howSaving.map((choice, i) => (
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="How have your employees been saving for retirement and/or other goals"
                                id={`howSaving` + i}
                                value={choice}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`howSaving` + i}
                              >
                                {choice}
                              </label>
                            </div>
                          ))}

                          <div className="row ml-3">
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                type="text"
                                name="How have your employees been saving for retirement and/or other goals"
                                id="howSaving"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="retirementBenefits">
                            Total company amount funded per year for employee
                            retirement benefits:
                          </label>
                          {retirementBenefits.map((choice, i) => (
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="Total Company amount funded per year for employee retirement benefits"
                                id={`retirementBenefits` + i}
                                value={choice}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`retirementBenefits` + i}
                              >
                                {choice}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="otherBenefits">
                            What other benefits to you provide for your
                            employees?
                          </label>
                          {otherBenefits.map((choice, i) => (
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="What other benefits to you provide for your employees?"
                                id={`otherBenefits` + i}
                                value={choice}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`otherBenefits` + i}
                              >
                                {choice}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12  d-flex justify-content-center">
                    <div className="form-group">
                      <div className="webriq-recaptcha" />
                    </div>
                  </div>

                  <div className="col-12  d-flex justify-content-center">
                    <button
                      className="bttn m-2"
                      type="submit"
                      style={{ fontSize: "14px" }}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default SmallBusinessOwnersQuestionnaire;

export const smallBusinessOwnersQuestionnaireQuery = graphql`
  query smallBusinessOwnersQuestionnaireQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`;
